/**
* @desc the ngInsuranceOneAxa provides support for displaying information of cancelation insurance in templates. This template is used in Purchase2
* @example <div ng-insurance-one-axa insurance="insurance" passengers="passengers" checked="insuranceselected.isAnnul" insuranceonce="insuranceonce"></div>
*/

webClient.directive('ngInsuranceOptionOne', ['$window', 'templateService', function ($window, templateService) {
    var directive = {};
    // GetPriceAmountDecAndInt
   
    directive.restrict = 'EA';
    directive.scope = {
        insurance: "=",
        passengers: "=",
        checked: "=",
        insuranceonce: "=",
        request: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_InsuranceOption1TPL.html');

        //Initialize Variables
        $scope.getUrl = $window.getUrl;
        $scope.getTemplateUrl = $window.getTemplateUrl;
        $scope.siteType = $window.site.type;
        $scope.getStaticFolder = $window.getStaticFolder;
        $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
        $scope.getTypeStaticFolder = $window.getTypeStaticFolder;
        $scope.getText = function (key) { return $window.culture.texts[key]; };
        $scope.adults = $scope.request.adults;
        $scope.childs = $scope.request.childs;
        $scope.infants = $scope.request.infants;
        $scope.infantAmount = 0;
        $scope.lng = lng;
        $scope.getPriceDecAndInt = function (price) {
            var priceData = {};
            var priceSplit = price.toString().split('.');
            priceData.int = priceSplit[0];
            if (priceSplit.length > 1) {
                priceData.dec = priceSplit[1];
            } else {
                priceData.dec = "00";
            }
            var result = priceData.int + "," + priceData.dec;
            return result;
        }
        if ($scope.insurance.infantPaying)
            $scope.infantAmount = $scope.insurance.totalPrice;

        $scope.insurance_int = ($scope.insurance.totalPrice * $scope.insurance.quantity).getIntegers();
        $scope.insurance_dec = ($scope.insurance.totalPrice * $scope.insurance.quantity).getDecimals().padLeft("00");

        /**Select insurance        
         * @param {number} item recibe 1 si el usuario agrega el seguro, 0 si lo quita
         */
        $scope.selectInsurance = function (item) {
            var insuranceTotalValue = 0;
            var quantityInsurance = 0;
            if (item === 1) {
                insuranceTotalValue = $scope.insurance.totalPrice;
                quantityInsurance = $scope.insurance.prices.length;
            }
            $window.sessionStorage.setItem("inssurance.flexy.selectedOffer", null);
            $window.sessionStorage.setItem("inssurance.flexyRequest.selectedOffer", null);
            $scope.$emit("onInsuranceClick", {
                id: $scope.insurance.insuranceId,
                type: $scope.insurance.insuranceReference,
                amount: insuranceTotalValue,
                company: $scope.insurance.companyReference,
                quantity: quantityInsurance,
                prices: $scope.insurance.prices
            });
        };        
    };
    return directive;
}]);
