webClient.controller('AirPayment', ['$scope', '$window', '$timeout', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'trackingService', 'gtmProduct', 'omnitureService',
    function ($scope, $window, $timeout, tseApiClient, tseUtil, airHelper, trackingService, gtmProduct, omnitureService) {
        $scope.getText = function (key) { return window.culture.texts[key]; };
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        var searchUrl = $window.site.currentAirRequest.searchUrl + "?" + tseUtil.Location.getQueryString();

        var bkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);
        $scope.bkgData = bkgData;
        $scope.getTemplateUrl = $window.getTemplateUrl;
        window.$scope = $scope;
        window.displayBrandedFaresSummary = true;
        $scope.accommodation = null;
        $scope.webProcessed = false;
        $scope.showFlexy = window.localStorage.getItem("showFlexy") === true;
        $scope.isUpsell = $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';
        getBundleWidget("mrfly-widget-loader.js").done(function () {

        });

        //Tracking
        try {
            //GTM
            if (trackingService.isActivated()) {
                trackingService.initDataLayer('checkout');
                trackingService.loadSessionData('step', 3);
                trackingService.setOption($scope.bkgData.bookingResponse.paymentMethod.paymentMethodName);
                trackingService.parseScope($scope);
                trackingService.pushDataLayer('step', 4);
            }

            //omniture
            if (omnitureService.isActivated()) {
                omnitureService.sendEvent(omnitureService.PURCHASE_4_VOL, omnitureService.FLIGHT_TUNNEL, null);
            }
        }
        catch (ex) {
            console.log(ex.message);
            $window.trackingError = ex.message;
        }
        var getBasketDetails = function () {
            var siteTypeIsB2b = $window.site.type === 2;
            var proposal = $scope.bkgData.purchaseResponse.proposalsRequested;
            var mandatoryFees = [];
            var totalAccommodations = siteTypeIsB2b ? $scope.bkgData.agencyMarginHotel.value : 0;
            $scope.bkgData.accommodations.forEach(function (accommodation) {
                totalAccommodations += siteTypeIsB2b ? accommodation.totalPriceRacknet : accommodation.totalPriceForcedPrice;
            });
            mandatoryFees.push({
                flexyDescription: getT('airbooking.flightprice'),
                totalPrice: $scope.bkgData.prixTotal.value,
                prices: []
            });
            mandatoryFees.push({
                flexyDescription: getT('mrfly-widget-accommodation-info.hotelprice'),
                totalPrice: totalAccommodations,
                prices: []
            });
            if (proposal.mandatoryFees) {
                proposal.mandatoryFees.forEach(function (mandatoryFee) {
                    mandatoryFee.flexyDescription = getT('mrfly-widget-basket.bookingfees');
                });
                Array.prototype.push.apply(mandatoryFees, proposal.mandatoryFees);
            }
            return mandatoryFees;
        }
        $scope.LoadVueJsWidgets = function () {
            if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {


                getBundleWidget("mrfly-widget-accommodation-info.js").done(function () {
                    //Get from session
                    var accommodationKey = 'accommodation_' + $scope.bkgData.accommodations[0].accommodationId;
                    var accommodationJson = $window.sessionStorage.getItem(accommodationKey);
                    var accommodation = JSON.parse(accommodationJson);

                    //nights number
                    var nights = moment(accommodation.to).diff(moment(accommodation.from), "days");
                    accommodation.nightNumber = nights;

                    mrflyWidgets['mrfly-widget-accommodation-info'].locale = $window.lng.substring(0, 2);
                    mrflyWidgets['mrfly-widget-accommodation-info'].accommodation = accommodation;
                    mrflyWidgets['mrfly-widget-accommodation-info'].siteType = window.site.type;
                    if ($scope.bkgData.agencyMarginHotel) {
                        mrflyWidgets['mrfly-widget-accommodation-info'].agencyMarginHotel = siteTypeIsB2b ? $scope.bkgData.agencyMarginHotel.value : 0;
                    }
                })
                    .fail(function (jqxhr, settings, exception) {
                        console.log(exception);
                    });


                getBundleWidget("mrfly-widget-basket-total-price.js").done(function () {
                    mrflyWidgets['mrfly-widget-basket-total-price'].price = $scope.bkgData.totalBasketPrice();
                    var extraDetails = [];

                    var bags = basketHelper.getAdditionalBagsPriceDetails($scope.siteType, $scope.bkgData.bookingRequest.passengers);
                    var insurances = basketHelper.getInsurancePriceDetails($scope.siteType, $scope.bkgData.bookingRequest.insurance, $scope.bkgData.purchaseResponse.proposalsRequested.insurances);
                    Array.prototype.push.apply(extraDetails, bags);
                    Array.prototype.push.apply(extraDetails, insurances);

                    //Seatmap
                    var seatmapObj = JSON.parse(window.sessionStorage.getItem('selectedSeats_' + $scope.bkgData.purchaseResponse.purchaseId));
                    if (seatmapObj != null) {
                        var seatmap = basketHelper.getSeatMapPriceDetails($scope.siteType, seatmapObj);
                        Array.prototype.push.apply(extraDetails, seatmap);
                    }

                    //Discount 
                    if ($scope.bkgData.discountInfo) {
                        var discount = {
                            description: getT('airbooking.discountcode'),
                            totalPrice: $scope.bkgData.discountInfo.applicableAmount,
                            details: [],
                            type: 'DISCOUNT'
                        };

                        Array.prototype.push.apply(extraDetails, [ discount ]);
                    }


                    var basketDetails = basketHelper.getPriceDetails(
                        $window.site.type,
                        $scope.bkgData.purchaseResponse.proposalsRequested,
                        $scope.bkgData.accommodations,
                        siteTypeIsB2b ? $scope.bkgData.agencyMarginHotel : { value: 0, margin: 0 },
                        extraDetails,
                        $scope.bkgData.purchaseResponse.productIsTrain ? "RAIL" : "FLIGHT",
                    );

                    mrflyWidgets['mrfly-widget-basket-total-price'].basketDetails = basketDetails;
                })
                    .fail(function (jqxhr, settings, exception) {
                        console.log(exception);
                    });

            }
        };

        if (bkgData) {


            bkgData.totalBasketPrice = function () {
                if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {
                    //never ever use totalPriceForcedPrice in B2B!
                    var proposal = $scope.bkgData.purchaseResponse.proposalsRequested;
                    var mandatoryOptionsFees = 0;
                    if (proposal.mandatoryFees) {
                        mandatoryOptionsFees = proposal.mandatoryFees.reduce(function (accumulator, mandatoryFee) {
                            return accumulator + mandatoryFee.totalPrice;
                        }, 0);
                    }
                    if (siteTypeIsB2b) {
                        // mandatory Options
                        var insurance = 0;
                        if ($scope.bkgData.insurancesSelected.isRepatriament != null && $scope.bkgData.insurancesSelected.isRepatriament.amount != null) {
                            insurance = parseFloat($scope.bkgData.insurancesSelected.isRepatriament.amount);
                        }
                        var totalAmount = ($scope.bkgData.prixTotalWithoutMargin.value - insurance + $scope.bkgData.accommodations[0].totalPriceRacknet);
                        $scope.bkgData.agencyMarginHotel.value = getAmountFromMargin(totalAmount, $scope.bkgData.agencyMarginHotel.margin);
                        var basketPrice = totalAmount + $scope.bkgData.agencyMarginHotel.value + insurance + mandatoryOptionsFees;
                        console.log("AirPaymentBody.B2B.basketPrice", basketPrice);
                        return basketPrice;
                    }
                    else {
                        var basketPrice = $scope.bkgData.prixTotal.value + $scope.bkgData.accommodations[0].totalPriceForcedPrice + mandatoryOptionsFees;
                        console.log("AirPaymentBody.B2C.basketPrice", basketPrice);
                        return basketPrice;
                    }
                } else {
                    var basketPrice = $scope.bkgData.prixTotal.value;
                    console.log("AirPaymentBody.Other.basketPrice", basketPrice);
                    return basketPrice;
                }
            };

            var keyGenerated = "";
            keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;
            bkgData.bookingRequest = tseUtil.Session.get("bookingRequest_" + keyGenerated);

            if (bkgData.bookingRequest != null && bkgData.bookingRequest.requestId == bkgData.request.requestId) {
                var _insuranceSelected = tseUtil.Session.get("insurancesSelected_" + keyGenerated);
                bkgData.insurancesSelected = _insuranceSelected;

                bkgData.banqueCasinoSchedule = tseUtil.Session.get("banqueCasino" + keyGenerated);
                bkgData.banqueCasino = tseUtil.Session.get("banqueCasinoStatus" + keyGenerated);

                window.sendDoubleClick("boutpaiv", bkgData.purchaseResponse.itineraries[0].origin.airportIata, bkgData.purchaseResponse.itineraries[0].destination.airportIata, bkgData.bookingRequest.passengers.length, bkgData.prixTotal.value);

                if (bkgData.paymentMethods) {
                    if (bkgData.bookingRequest.paymentMethod.paymentMethodReference == "CB" && bkgData.bookingRequest.paymentMethod.transactionMethod == 1) {
                        $scope.webProcessed = true;
                    }
                    else if (bkgData.bookingRequest.paymentMethod.paymentMethodReference == "CAS" && bkgData.bookingRequest.paymentMethod.transactionMethod == 1) {
                        $scope.webProcessed = true;
                    }
                }

                if (bkgData.accommodations && bkgData.accommodations.any()) {
                    $scope.LoadVueJsWidgets();
                }


                $scope.updateBookingSummary = function () {
                    $scope.$broadcast("onBookingSummaryUpdate");
                };
                $scope.$on("onBookingSummaryUpdateCompleted", function (event, args) {
                    $scope.prixTotalWithoutMargin = bkgData.prixTotalWithoutMargin;
                    console.log($scope.prixTotalWithoutMargin);
                });
                $scope.updateBookingSummary();

                $timeout(function () {
                    XLFormInit({ iCont: 'i-tab-card-1' });
                    if ($scope.webProcessed)
                        XLFormInit({ iCont: 'i-tabs-screen-1' });
                    else
                        XLFormInit({ iCont: 'i-tabs-screen-2' });
                });

                $scope.setBankCardReference = function (key) {
                    $scope._cardReference = key;
                };

                $scope.getCaisnoBackUrl = function () {
                    if (tseUtil.Session.get("Step3Url")) {
                        return tseUtil.Session.get("Step3Url");
                    } else {
                        return window.location.href;
                    }
                };

                $scope.goTo = function (item) {
                    if (!$scope.webProcessed) {
                        console.log("Processing new payment method.");
                        if (XLFormValid({ iCont: 'i-tabs-screen-2' })) {
                            window.mrflyWidgets['airbooking-process-loader'].$store.commit("showLoader");
                            var cardReference = $scope._cardReference;
                            var backUrl = $scope.getCaisnoBackUrl();
                            console.log("Processing backUrl") + backUrl;
                            var objToSend = {
                                "requestId": bkgData.bookingRequest.requestId,
                                "paymentId": bkgData.bookingResponse.paymentId,
                                "returnUrl": $window.returnUrlFor3DS,
                                "backUrl": backUrl,
                                "transactionMethod": $scope.bkgData.bookingRequest.paymentMethod.transactionMethod,
                                "culture": $window.site.culture,
                                "paymentMethod": $scope.bkgData.bookingRequest.paymentMethod,
                                "purchaseId": $scope.bkgData.purchaseResponse.purchaseId
                            };
                            if (cardReference != undefined) {
                                var card = bkgData.bookingRequest.paymentMethod.bankCard.bankCards.first(function (item) { return item.bankCardReference == cardReference });
                                objToSend.bankcard = {
                                    "bankCardId": card.bankCardId,
                                    "reference": cardReference
                                };
                            }

                            if (window.site.partnerId == "servair") {
                                var selectedProduct = window.sessionStorage.getItem("selectedProduct");
                                //PostTotal Before Payment and get result 
                                var bookingNumber = null;
                                var origineAirportIata;
                                var destinationAirportIata;
                                if ($scope.bkgData != null && $scope.bkgData.bookingResponse != undefined && $scope.bkgData.bookingResponse.numbers.length > 0) {
                                    bookingNumber = $scope.bkgData.bookingResponse.numbers[0].bookingNumber;
                                    origineAirportIata = bkgData.purchaseResponse.itineraries[0].origin.airportIata;
                                    destinationAirportIata = bkgData.purchaseResponse.itineraries[0].destination.airportIata;
                                }
                                var aireportIata = origineAirportIata + destinationAirportIata;
                                $window.Typescript.Servair.Services.ServairService.doPostTotal(bkgData.bookingRequest.requestId, $window.site.currentAirRequest.bookingId, selectedProduct, bookingNumber, aireportIata).then(function (result) {

                                    if (result.data === true) {

                                        $scope.apiClient.processPayment(objToSend).success(function (data) {
                                            var finishUrl = tseUtil.Url.appendValueToUrlBefore($window.site.currentAirRequest.finishUrl, "prqid", data.paymentRequestId);
                                            finishUrl = tseUtil.Url.appendValueToUrlBefore(finishUrl, "webP", 1);
                                            var selectedProduct = window.sessionStorage.getItem("selectedProduct");
                                            finishUrl = tseUtil.Url.appendValueToUrl(finishUrl, "selectedproduct", selectedProduct);
                                            window.location.href = finishUrl;
                                        });
                                    }
                                    else {
                                        //redirect to error page because postTotal failed
                                        document.location.href = "/airpaymentresponse/" + bkgData.bookingRequest.requestId + "/" + $window.site.currentAirRequest.bookingId + "?posttotalfailed=1";
                                    }

                                });


                            }

                            else {

                                $scope.apiClient.processPayment(objToSend).success(function (data) {
                                    var finishUrl = tseUtil.Url.appendValueToUrlBefore($window.site.currentAirRequest.finishUrl, "prqid", data.paymentRequestId);
                                    finishUrl = tseUtil.Url.appendValueToUrlBefore(finishUrl, "webP", 1);
                                    window.location.href = finishUrl;
                                });
                            }


                        }
                    }
                    else {
                        var dateNow = new Date();
                        var isValidDates = false;
                        var cardMonth = parseInt($("#i-card-mm-text").html());
                        var cardYear = parseInt($("#i-card-aa-text").html());
                        var cardDate = new Date(cardYear, cardMonth, 1, 0, 0, 0, 0);
                        cardDate.setDate(cardDate.getDate() - 1);

                        isValidDates = cardMonth > 0 && cardYear > 0;

                        if (bkgData.banqueCasino == 2 && bkgData.bookingRequest.paymentMethod.paymentMethodReference == "CAS") {

                            var arrPaymentSchedule = [];
                            arrPaymentSchedule = $scope.bkgData.banqueCasinoSchedule.paymentsSchedules;
                            arrPaymentSchedule = arrPaymentSchedule.groupByKey('date');
                            arrPaymentSchedule = objectToArray(arrPaymentSchedule);
                            arrPaymentSchedule.each(function (item) {
                                if (isArray(item.value))
                                    item.mindate = item.value.min(function (vitem) {
                                        var dateNum = moment(vitem.date).toDate().getTime();
                                        return dateNum;
                                    });
                                else
                                    item.mindate = moment(item.value.date).toDate().getTime();
                            });
                            arrPaymentSchedule = arrPaymentSchedule.orderBy(function (item) { return item.mindate; });
                            var lastDateSchedule = new Date(arrPaymentSchedule[arrPaymentSchedule.length - 1].key);
                        }

                        if (isValidDates && (cardMonth < dateNow.getUTCMonth() + 1) && cardYear <= dateNow.getUTCFullYear()) {
                            $("#i-mm-card").removeClass("c-field-ok");
                            $("#i-mm-card").addClass("c-field-ko");
                            FErrorShow({ oObj: document.getElementById("i-mm-card"), vErrorCode: 'data-error', vErrorCustom: "Date d'expiration invalide" });
                        }
                        else {
                            if (bkgData.banqueCasino == 2 && bkgData.bookingRequest.paymentMethod.paymentMethodReference == "CAS" && isValidDates && (cardDate < lastDateSchedule)) {
                                $("#i-mm-card").removeClass("c-field-ok");
                                $("#i-mm-card").addClass("c-field-ko");
                                $("#i-aa-card").removeClass("c-field-ok");
                                $("#i-aa-card").addClass("c-field-ko");
                                $window.XLConfirm({ vTitle: "La carte saisie n'est pas valide pour le paiement en 4x car elle expire avant la dernière échance. Pour continuer, veuillez saisir une autre carte ou utiliser un autre mode de paiement.", vTextYes: "OK", vHideNo: true });
                            }
                            else {
                                if (XLFormValid({ iCont: 'i-tabs-screen-1' })) {
                                    var cardReference = $scope._cardReference;
                                    var cardName = $("#i-sec-owner").val();
                                    var cardNumber = $("#i-sec-num").val();
                                    var cardDateMM = $("#i-card-mm-text").html();
                                    var cardDateAA = $("#i-card-aa-text").html();
                                    var cardCVV = $("#i-sec-ccv").val();

                                    $window.FLoading({ vAction: 'show' });
                                    //$window.sendAnalytics('/Flightsearch/Payment/CBwait', 'Payment CB Wait');
                                    //Tracking
                                    try {
                                        //GTM
                                        if (trackingService.isActivated()) {
                                            trackingService.pushPageData('/Flightsearch/Payment/CBwait', 'Payment CB Wait', lng, false);
                                        }
                                    }
                                    catch (ex) {
                                        console.log(ex.message);
                                        $window.trackingError = ex.message;
                                    }
                                    //$window.sendXiti("tunnel_resavol::attente_paiement_resavol", 1);
                                    $window.sendInspectlet("pageUrl", $window.getUrl('Flightsearch/Payment/CBwait'));

                                    $window.site.pushNoBackOnHistory();

                                    var card = null;

                                    if (bkgData.bookingRequest.paymentMethod.paymentMethodReference == "CB") {
                                        card = bkgData.bookingRequest.paymentMethod.bankCard.bankCards.first(function (item) { return item.bankCardReference == cardReference });
                                    }

                                    if (bkgData.bookingRequest.paymentMethod.paymentMethodReference == "CAS") {
                                        card = bkgData.bookingRequest.paymentMethod.banqueCasino.bankCards.first(function (item) { return item.bankCardReference == cardReference });
                                    }


                                    if (window.site.partnerId == "servair") {
                                        var selectedProduct = window.sessionStorage.getItem("selectedProduct");
                                        //PostTotal Before Payment and get result 
                                        var bookingNumber = null;
                                        var origineAirportIata;
                                        var destinationAirportIata;
                                        if ($scope.bkgData != null && $scope.bkgData.bookingResponse != undefined && $scope.bkgData.bookingResponse.numbers.length > 0) {
                                            bookingNumber = $scope.bkgData.bookingResponse.numbers[0].bookingNumber;
                                            origineAirportIata = bkgData.purchaseResponse.itineraries[0].origin.airportIata;
                                            destinationAirportIata = bkgData.purchaseResponse.itineraries[0].destination.airportIata;
                                        }
                                        var aireportIata = origineAirportIata + destinationAirportIata;
                                        $window.Typescript.Servair.Services.ServairService.doPostTotal(bkgData.bookingRequest.requestId, $window.site.currentAirRequest.bookingId, selectedProduct, bookingNumber, aireportIata).then(function (result) {

                                            if (result.data === true) {

                                                $scope.apiClient.processPayment({
                                                    "requestId": bkgData.bookingRequest.requestId,
                                                    "purchaseId": $scope.bkgData.purchaseResponse.purchaseId,
                                                    "paymentId": bkgData.bookingResponse.paymentId,
                                                    "returnUrl": $window.returnUrlFor3DS,

                                                    "culture": $window.site.culture,
                                                    "paymentMethod": $scope.bkgData.bookingRequest.paymentMethod,
                                                    "transactionMethod": $scope.bkgData.bookingRequest.paymentMethod.transactionMethod,
                                                    "bankcard": {
                                                        "bankCardId": card.bankCardId,
                                                        "reference": cardReference,
                                                        "cardholder": cardName,
                                                        "number": cardNumber.replace(/ /g, ""),
                                                        "expiryMonth": (cardDateMM) ? cardDateMM.substr(0, 2) : null,
                                                        "expiryYear": (cardDateAA) ? cardDateAA.substr(2, 2) : null,
                                                        "cvv": cardCVV
                                                    }
                                                }).success(function (data) {
                                                    var finishUrl = tseUtil.Url.appendValueToUrlBefore($window.site.currentAirRequest.finishUrl, "prqid", data.paymentRequestId);
                                                    var selectedProduct = window.sessionStorage.getItem("selectedProduct");
                                                    finishUrl = tseUtil.Url.appendValueToUrl(finishUrl, "selectedproduct", selectedProduct);
                                                    window.location.href = finishUrl;
                                                });

                                            }
                                            else {
                                                //redirect to error page because postTotal failed
                                                document.location.href = "/airpaymentresponse/" + bkgData.bookingRequest.requestId + "/" + $window.site.currentAirRequest.bookingId + "?posttotalfailed=1";
                                            }

                                        });

                                    }
                                    else {
                                        $scope.apiClient.processPayment({
                                            "requestId": bkgData.bookingRequest.requestId,
                                            "purchaseId": $scope.bkgData.purchaseResponse.purchaseId,
                                            "paymentId": bkgData.bookingResponse.paymentId,
                                            "returnUrl": $window.returnUrlFor3DS,

                                            "culture": $window.site.culture,
                                            "paymentMethod": $scope.bkgData.bookingRequest.paymentMethod,
                                            "transactionMethod": $scope.bkgData.bookingRequest.paymentMethod.transactionMethod,
                                            "bankcard": {
                                                "bankCardId": card.bankCardId,
                                                "reference": cardReference,
                                                "cardholder": cardName,
                                                "number": cardNumber.replace(/ /g, ""),
                                                "expiryMonth": (cardDateMM) ? cardDateMM.substr(0, 2) : null,
                                                "expiryYear": (cardDateAA) ? cardDateAA.substr(2, 2) : null,
                                                "cvv": cardCVV
                                            }
                                        }).success(function (data) {
                                            var finishUrl = tseUtil.Url.appendValueToUrlBefore($window.site.currentAirRequest.finishUrl, "prqid", data.paymentRequestId);
                                            window.location.href = finishUrl;
                                        });

                                    }

                                }
                            }
                        }
                    }
                };

            }
            else {
                $window.FLoading({ vAction: 'show' });
                window.location.href = searchUrl;
            }
        }
        else {
            $window.FLoading({ vAction: 'show' });
            window.location.href = searchUrl;
        }
        onReady(function () {
            var currentYear = (new Date()).getFullYear();
            var yearList = document.getElementById("i-expiration-year");
            if (yearList) {
                for (var i = 0; i <= 10; i++) {
                    var listItem = document.createElement("li");
                    yearList.appendChild(listItem);
                    listItem.setAttribute("data-value", currentYear);
                    listItem.innerHTML = currentYear;
                    currentYear = currentYear + 1;
                }
            }
        });

        //Taxes details click
        $scope.$on("displayTaxesDetailsClick", function (event, args) {
            $scope.displayTaxesDetailModal = true;
            $scope.modalTaxesKey = args.key.replaceAll(":", "-");
            $scope.modalTaxesKey = $scope.modalTaxesKey.replaceAll("\\|", "-");
            $scope.modalTaxesKeyAux = $scope.modalTaxesKey + "-" + args.paxFares.paxType;
            $scope.modalTaxesPaxFares = args.paxFares;

            $scope.modalShowMargin = false;

            $scope.$broadcast("updateTaxesDetailModalData", { key: $scope.modalTaxesKey, fares: $scope.modalTaxesPaxFares });

            //Remodal workaround
            $(document).find(".remodal").each(function (a, c) {
                var d = $(c), e = d.data("remodal-options");
                e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                    d["remodal"](e)
            });

            $timeout(function () {
                $("[data-remodal-id='taxes-detail-modal-" + $scope.modalTaxesKeyAux + "']").remodal().open();
            },
                200, false);

        });

        //Luggage details click
        $scope.$on("displayLuggageDetailsClick", function (event, args) {
            $scope.displayLuggageDetailModal = true;
            $scope.modalLuggageBagType = args.bagType;

            $scope.$broadcast("updateLuggageDetailModalData", { bagType: $scope.modalLuggageBagType });

            $scope.modalShowMargin = false;

            var airlines = JSON.parse(window.sessionStorage.getItem("airlines"));

            args.marketingCarriers.each(function (value, key) {

                if (airlines != null && airlines[value].showMargin == true || value === 'AF') {
                    $scope.modalShowMargin = true;
                }
            });

            //Remodal workaround
            $(document).find(".remodal").each(function (a, c) {
                var d = $(c), e = d.data("remodal-options");
                e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                    d["remodal"](e)
            });

            $timeout(function () {
                $('[data-remodal-id="luggage-detail-modal"]').remodal().open();
            },
                200, false);
        });
    }]);