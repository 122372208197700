/**
* @desc the ngBookingPageTwo provides support for collect and displaying information of insurances avalaible. This is used in AirBookingBody
* @example:
* <div ng-booking-page-two request="bkgData.request" flexy="bkgData.bookingRequest.flexyFare" proposal="proposal" passengers="paxAmount" paxname="paxType" paxinfo="bkgData.bookingRequest.paxes" datelastarrival="lastDate" cards="cards" insurances="bkgData.insurances" insuranceonce="bkgData.insuranceOnce" insuranceselected="bkgData.insurancesSelected" additionaldata="bkgData.bookingRequest.additionalData" bkgdata="bkgData"></div>
*/

webClient.directive('ngPurchaseStepTwo', ['$window', 'templateService', 'tseApiClient',
    function ($window, templateService, tseApiClient) {
        var directive = {};
        directive.restrict = 'EA';
        directive.scope = {
            request: "=",
            flexy: "=",
            proposal: "=",
            passengers: "=",
            paxname: "=",
            paxinfo: "=",
            datelastarrival: "=",
            cards: "=",
            insurances: "=",
            insuranceonce: "=",
            insuranceselected: "=",
            additionaldata: "=",
            bkgdata: "=",
            hideinsurance: "=",
            enablefreeflex: "="
        };
        directive.link = function ($scope, $element) {

            templateService.compile($scope, $element, 'tpl/_PurchaseStep2TPL.html');

            //Initialize Variables
            $scope.getUrl = $window.getUrl;
            $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
            $scope.getTemplateUrl = $window.getTemplateUrl;
            $scope.siteType = $window.site.type;
            $scope.insurancesKey = {};
            $scope.siteType = $window.site.type;
            $scope.enableSendParameters = $window.enableSendParameters;
            $scope.adults = $scope.bkgdata.request.adults; //Number by type of passenger
            $scope.childs = $scope.bkgdata.request.childs; //Number by type of passenger
            $scope.infants = $scope.bkgdata.request.infants;//Number by type of passenger
            $scope.flightWithin24Hour = (new Date($scope.bkgdata.purchaseResponse.itineraries[0].departureDateTime)  - new Date()) / (1000 * 60 * 60) <= 24;
            $window._innerScope = $scope;
            if ($window.isWonderBox) $scope.isWonderBox = $window.isWonderBox;
            if ($window.isAirbus) $scope.isAirbus = $window.isAirbus;
            if ($window.isServair) $scope.isServair = $window.isServair;
            if ($scope.insurances)
                $scope.insurancesKey = $scope.insurances.groupByKey("companyReference");            

            for (var x in $scope.insurancesKey) {
                $scope.insurancesKey[x] = $scope.insurancesKey[x].groupByKey("insuranceReference");
            }

            if ((window.sessionStorage.getItem("inssurance.repatriation.selected") == false)
                && window.sessionStorage.getItem("inssurance.repatriation.isChecked") == false) {
                mrflyWidgets["mrfly-widget-inssurance-repatriation"].checked = true;
            }




            $scope.loadInsuranceRepatriation = function () {

                var repatriationInsurances = $scope.proposal.insurances.filter(function (a) { return /00009_bis|00003|0004|00005|00006|00009|00010|00011/.test(a.insuranceReference); });
                if (repatriationInsurances && repatriationInsurances.length > 0) {
                    var ins = repatriationInsurances[0];


                    //TODO FFV2: check if this condition is still required
                    //var showWidget = $scope.flexy || $scope.enablefreeflex || $window.isWonderBox;

                    //if (showWidget) {
                    getBundleWidget("mrfly-widget-inssurance-repatriation.js").done(function () {
                        mrflyWidgets['mrfly-widget-inssurance-repatriation'].adtNumber = $scope.adults;
                        mrflyWidgets['mrfly-widget-inssurance-repatriation'].cnnNumber = $scope.childs;
                        mrflyWidgets['mrfly-widget-inssurance-repatriation'].infNumber = $scope.infants;
                        mrflyWidgets['mrfly-widget-inssurance-repatriation'].prices = ins.prices;
                        mrflyWidgets['mrfly-widget-inssurance-repatriation'].totalPrice = ins.totalPrice;
                        mrflyWidgets['mrfly-widget-inssurance-repatriation'].insuranceReference = ins.insuranceReference;
                        mrflyWidgets['mrfly-widget-inssurance-repatriation'].infantPaying = ins.infantPaying;
                        mrflyWidgets['mrfly-widget-inssurance-repatriation'].selected = window.sessionStorage.getItem("inssurance.repatriation.selected") === 'true';
                        if (window.sessionStorage.getItem("inssurance.repatriation.isChecked") == "true") {
                            mrflyWidgets["mrfly-widget-inssurance-repatriation"].checked = true;
                        } else if (window.sessionStorage.getItem("inssurance.repatriation.isChecked") == "false") {
                            mrflyWidgets["mrfly-widget-inssurance-repatriation"].checked = false;
                        } else {
                            if ((window.site.type === 2) || (window.site.type === 9)) {
                                mrflyWidgets["mrfly-widget-inssurance-repatriation"].checked = true;
                            }
                        }
                        
                        //free insurance for cdiscount
                        if (ins.totalPrice === 0 && window.partnerName === "volcdiscount") {
                            $scope.$emit("onInsuranceClick", {
                                id: ins.insuranceId,
                                type: ins.insuranceReference,
                                amount: ins.totalPrice,
                                company: ins.companyReference,
                                quantity: ins.prices.length,
                                prices: ins.prices
                            });
                        }
                        mrflyWidgets['mrfly-widget-inssurance-repatriation'].$on('isChecked', function (noOption) {
                            if (noOption && mrflyWidgets["mrfly-widget-inssurance-repatriation"].showAlert) {
                                mrflyWidgets["mrfly-widget-inssurance-repatriation"].showAlert = false;
                            }
                            window.sessionStorage.setItem("inssurance.repatriation.isChecked", noOption);

                        });

                        //event when freeflex is selected or not
                        mrflyWidgets['mrfly-widget-inssurance-repatriation'].$on('repatriationInssuranceSelected', function (selected) {
                            //Set session totalflex
                            window.sessionStorage.setItem("inssurance.repatriation.selected", selected);
                            if (selected && mrflyWidgets["mrfly-widget-inssurance-repatriation"].showAlert) {
                                mrflyWidgets["mrfly-widget-inssurance-repatriation"].showAlert = false;
                            }
                            var insuranceTotalValue = 0;
                            if (selected) {
                                insuranceTotalValue = ins.totalPrice;
                            }

                            $scope.$emit("onInsuranceClick", {
                                id: ins.insuranceId,
                                type: ins.insuranceReference,
                                amount: insuranceTotalValue,
                                company: ins.companyReference,
                                quantity: ins.prices.length,
                                prices: ins.prices

                            });

                            console.log("inssurance.repatriation.selected : " + selected);
                        });

                        var params = $window.getUrlVars();
                        if (params['repatriation']) {
                            if (params['repatriation'] == "true") {
                                mrflyWidgets['mrfly-widget-inssurance-repatriation'].selected = JSON.parse(params['repatriation']);
                            } else {
                                mrflyWidgets['mrfly-widget-inssurance-repatriation'].selected = false;
                            }
                        }
                    });
                    //}
                } else {
                    window.sessionStorage.setItem("inssurance.repatriation.isChecked", true);
                }

            };

            $scope.$on("insurancesupdated", function () {
                $scope.loadInsuranceRepatriation();
            });

            $scope.loadInsuranceRepatriation();

            $scope.saveAdditional = function (item) {
                if (item == 1) {
                    var opener = $("#btns").get(0);
                    $window.FSmoothOpen({ oOpener: opener, iSmooth: 'i-smooth-subedit-h' });
                }
                if (!$scope.enableSendParameters) {
                    $scope.additionaldata = [];
                }
                if ($("#i-check-h1").attr("checked")) $scope.additionaldata.push({ key: "baggageInfo", value: $scope.baggageInfo });
                if ($("#i-check-hhh1").attr("checked") && $("#i-check-h2").attr("checked")) $scope.additionaldata.push({ key: "cabinPetInfo", value: $scope.cabinPetInfo });
                if ($("#i-check-hhh2").attr("checked") && $("#i-check-h2").attr("checked")) $scope.additionaldata.push({ key: "cargoPetInfo", value: $scope.cargoPetInfo });
                if ($("#i-check-h3").attr("checked")) $scope.additionaldata.push({ key: "foodInfo", value: $scope.foodInfo });
                if ($("#i-check-h4").attr("checked")) $scope.additionaldata.push({ key: "assistanceInfo", value: $scope.assistanceInfo });
                if ($("#i-check-h5").attr("checked")) $scope.additionaldata.push({ key: "etaCountryInfo", value: $scope.etaCountryInfo });
                if ($("#i-check-h6").attr("checked")) $scope.additionaldata.push({ key: "weaponsInfo", value: $scope.weaponsInfo });
                if ($("#i-check-h7").attr("checked")) $scope.additionaldata.push({ key: "otherInfo", value: $scope.otherInfo });
            };

            $scope.cleanAdditional = function (item) {
                if (item == 1) {
                    var opener = $("#btns").get(0);
                    $window.FSmoothOpen({ oOpener: opener, iSmooth: 'i-smooth-subedit-h' });
                }
                $scope.baggageInfo = "";
                $scope.cabinPetInfo = "";
                $scope.cargoPetInfo = "";
                $scope.foodInfo = "";
                $scope.assistanceInfo = "";
                $scope.etaCountryInfo = "";
                $scope.weaponsInfo = "";
                $scope.otherInfo = "";
                if ($("#i-check-h1").attr("checked")) $("#i-check-h1").click();
                if ($("#i-check-h2").attr("checked")) $("#i-check-h2").click();
                if ($("#i-check-hhh1").attr("checked")) $("#i-check-hhh1").click();
                if ($("#i-check-hhh2").attr("checked")) $("#i-check-hhh2").click();
                if ($("#i-check-h3").attr("checked")) $("#i-check-h3").click();
                if ($("#i-check-h4").attr("checked")) $("#i-check-h4").click();
                if ($("#i-check-h5").attr("checked")) $("#i-check-h5").click();
                if ($("#i-check-h6").attr("checked")) $("#i-check-h6").click();
                if ($("#i-check-h7").attr("checked")) $("#i-check-h7").click();
            };

            $scope.$on("setOnce", function (event, args) {
                $scope.insuranceonce = 1;
            });

            if ($scope.bkgdata.banqueCasino == undefined) {
                $scope.bkgdata.banqueCasino = 1;
                $scope.bkgdata.showBanqueCasino = 0; //normal
            }

            $scope.setReady = function (value) {
                $window.FLoading({ vAction: 'showNow' });
                if (value == 2) {
                    var missing = 0;
                    if (typeof mrflyWidgets["mrfly-widget-inssurance-repatriation"] !== 'undefined' && mrflyWidgets["mrfly-widget-inssurance-repatriation"] !== null) {
                        if ((window.sessionStorage.getItem("inssurance.repatriation.isChecked") == "false" || window.sessionStorage.getItem("inssurance.repatriation.isChecked") == null)
                            && (window.sessionStorage.getItem("inssurance.repatriation.selected") == "false" || window.sessionStorage.getItem("inssurance.repatriation.selected") == null)
                        ) {
                            mrflyWidgets["mrfly-widget-inssurance-repatriation"].showAlert = true;
                            missing = -1;
                        } else {
                            mrflyWidgets["mrfly-widget-inssurance-repatriation"].showAlert = false;
                        }

                        //flex validation (check that user has checked eitehr an option or no-options)
                    }
                    if (missing < 0) {
                        $window.XLFormValid({ iCont: 'i-accord-step-2', vScrollTo: true });
                        if ($('#i-loading-cover').length) {
                            $window.FLoading({ vAction: 'hide' });
                        }
                    }
                    else {
                        $scope.saveAdditional(0);
                        var rq = {
                            value: value
                        }
                        $scope.$emit("getPurchasePaymentMethods", rq);
                    }
                }
                else {
                    $scope.$emit("setReadyStep", { step: value });
                }
               
            };

            if (!$scope.additionaldata) {
                $scope.cleanAdditional(0);
            } else if ($scope.additionaldata.groupByKey) {
                var addDataKey = $scope.additionaldata.groupByKey("key");
                if (addDataKey['baggageInfo']) { $scope.baggageInfo = addDataKey['baggageInfo'].value; $("#i-check-h1").click() }
                if (addDataKey['cabinPetInfo']) { $scope.cabinPetInfo = addDataKey['cabinPetInfo'].value; $("#i-check-h2").click(); $("#i-check-hhh1").click(); }
                if (addDataKey['cargoPetInfo']) { $scope.cargoPetInfo = addDataKey['cargoPetInfo'].value; $("#i-check-h2").click(); $("#i-check-hhh2").click(); }
                if (addDataKey['foodInfo']) { $scope.foodInfo = addDataKey['foodInfo'].value; $("#i-check-h3").click(); }
                if (addDataKey['assistanceInfo']) { $scope.assistanceInfo = addDataKey['assistanceInfo'].value; $("#i-check-h4").click(); }
                if (addDataKey['etaCountryInfo']) { $scope.etaCountryInfo = addDataKey['etaCountryInfo'].value; $("#i-check-h5").click(); }
                if (addDataKey['weaponsInfo']) { $scope.weaponsInfo = addDataKey['weaponsInfo'].value; $("#i-check-h6").click(); }
                if (addDataKey['otherInfo']) { $scope.otherInfo = addDataKey['otherInfo'].value; $("#i-check-h7").click(); }
            }

            setTimeout(function () {
                $window.scrollTo(0, $($("#i-purchase-123")).offset().top - 15);
            }, 100);

            //window.sendXiti("tunnel_resavol::assurances_resavol", 1);
        };
        return directive;
    }]);
