webClient.controller('AirBooking', ['$scope', '$window', '$timeout', '$location', '$i18next', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'bookingRequest', 'insurancesSelected', 'bookingRequestKey', 'insuranceOnce', 'trackingService',
    function ($scope, $window, $timeout, $location, $i18next, tseApiClient, tseUtil, airHelper, bookingRequest, insurancesSelected, bookingRequestKey, insuranceOnce, trackingService) {
    $scope.getText = function (key) { return window.culture.texts[key]; };
    $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
    var searchUrl = $window.site.currentAirRequest.searchUrl + "?" + tseUtil.Location.getQueryString();

    var user = window.site;
    var bkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);
    $scope.bkgData = bkgData;
    $window.$scope = $scope;
    $scope.enablePaypal = $window.enablePaypal;
    $scope.enableBrandedFares = $window.enableBrandedFares;
    $scope.siteType = $window.site.type;
    $scope.enableSendParameters = $window.enableSendParameters;
    $scope.enableFreeFlex = window.enableFreeFlex === true;
    if ($window.hideInsurance) $scope.hideInsurance = true;
    else $scope.hideInsurance = false;
    $scope.hideFlexy = false;
    var siteTypeIsB2b = $scope.siteType == 2;

    if (user && user.loginInfo && siteTypeIsB2b) {
        if (user.loginInfo.HideInsurance == true) {
            $scope.hideInsurance = true;
        }

        if (user.loginInfo.HideFlexy == true) {
            $scope.hideFlexy = true
        }
    }

    if (bkgData) {
        //Pax Init
        var paxType = ["Adulte", "Enfant", "Bébé"];
        var paxAmount = [bkgData.request.adults, bkgData.request.childs, bkgData.request.infants];

        bkgData.pricePredictions = null;
        $scope.apiClient.AIR.getPricePredictions(bkgData.selection).success(function (pData) {
            if (pData && pData.advice > 1) {
                bkgData.pricePredictions = pData;
                $scope.updateBookingSummary();
                var minValue = Math.round(bkgData.pricePredictions.days.min(function (day) { return Math.round(day.price.value) }));
                var maxValue = Math.round(bkgData.pricePredictions.days.max(function (day) { return Math.round(day.price.value) }));
                maxValue = maxValue - minValue;
                if (maxValue == 0) maxValue = 1;
                bkgData.pricePredictions.days.each(function (day) {
                    day.date = moment(day.date).toDate();
                    var currentValue = Math.round(day.price.value);
                    var change = currentValue - minValue;
                    //console.log([currentValue, change, maxValue, Math.round(change * 50 / maxValue) + 25])
                    day.percent = Math.round(change * 50 / maxValue) + 25;
                });
            }
        });

        $scope.newProcess = true;
        var keyGenerated = "";

        keyGenerated = bkgData.request.requestId + "_" + bkgData.selection.outbound.key;
        if (bkgData.selection.inbound)
            keyGenerated = keyGenerated + "_" + bkgData.selection.inbound.key;
        var init = true;
        // Object to send to API
        bkgData.bookingRequest = tseUtil.Session.get("bookingRequest_" + keyGenerated);
        if (bkgData.bookingRequest != null && bkgData.bookingRequest.requestId == bkgData.request.requestId)
            init = false;

        if (init) {
            bkgData.bookingRequest = {};
            bkgData.bookingRequest.requestId = bkgData.request.requestId;
            bkgData.bookingRequest.itinerariesKey = [];
            bkgData.bookingRequest.itinerariesKey.push(bkgData.selection.outbound.key);
            if (bkgData.selection.inbound != null)
                bkgData.bookingRequest.itinerariesKey.push(bkgData.selection.inbound.key);

            bkgData.bookingRequest.flexyFare = false;
            bkgData.bookingRequest.bestPriceFare = false;

            //WonderBox Code
            if (user && user.loginInfo && user.loginInfo.Code != null && user.loginInfo.Code!=undefined)
                bkgData.bookingRequest.wonderboxCode = user.loginInfo.Code;

            bkgData.bookingRequest.insurance = null; //{ company : "00001", reference: "00001" };
            bkgData.bookingRequest.paxes = []; //{ title, firstName, lastName, birthDate, fidelityCardType, fidelityCardNumber, foodType, passportNumber, passportExpireDate, passportCountry }

            for (var ptc in paxAmount)
                for (var i = 0; i < paxAmount[ptc]; i++)
                    bkgData.bookingRequest.paxes.push({ paxType: ptc });

            bkgData.bookingRequest.additionalData = []; // { 1: baggageInfo ,  2a: cabinPetsInfo, 2b: cargoPetsInfo, 3: foodInfo, 4: assistanceInfo, 5: etaCountryInfo, 6: weaponsInfo, 7: otherInfo}
            bkgData.bookingRequest.payer = {}; // {firstName, lastName, address, postalCode, city, country, telCountryCode, telNumber, email}
        }

        var _insuranceSelected = tseUtil.Session.get("insurancesSelected_" + keyGenerated);
        var _insuranceOnce = tseUtil.Session.get("insuranceOnce" + keyGenerated);
        bkgData.banqueCasinoSchedule = tseUtil.Session.get("banqueCasino" + keyGenerated);
        bkgData.banqueCasino = tseUtil.Session.get("banqueCasinoStatus" + keyGenerated);
        bkgData.banqueCasinoMessage = tseUtil.Session.get("banqueCasinoMessage" + keyGenerated);
        bkgData.banqueCasinoCustomerUnauthorized = tseUtil.Session.get("banqueCasinoCustomerUnauthorized" + keyGenerated);
        bkgData.showBanqueCasino = tseUtil.Session.get("banqueCasinoShowBanqueCasino" + keyGenerated);
        bkgData.paypalToken = tseUtil.Session.get("paypalToken" + keyGenerated);
        bkgData.paymentMethods = tseUtil.Session.get("paymentMethods" + keyGenerated);

        //AdditionalData URL 
        if ($scope.enableSendParameters) {
            var customURL = tseUtil.Location.getQueryStringVar("custom");
            var campaignURL = tseUtil.Location.getQueryStringVar("campaign");
            if (customURL) {
                bkgData.bookingRequest.additionalData.push({ key: "custom", value: customURL });
            }
            if (campaignURL) {
                bkgData.bookingRequest.additionalData.push({ key: "campaign", value: campaignURL });
            }
        }

        //Branded Fares
        bkgData.brandedFareRequested = tseUtil.Session.get("brandedFareRequested" + keyGenerated);
        bkgData.brandedFares = tseUtil.Session.get("brandedFares" + keyGenerated);
        bkgData.brandedSelectedIncludeBaggage = tseUtil.Session.get("brandedSelectedIncludeBaggage" + keyGenerated);
        bkgData.brandedSelectedBaggageText = tseUtil.Session.get("brandedSelectedBaggageText" + keyGenerated);
        bkgData.brandedSelectedBaggageDataTip = tseUtil.Session.get("brandedSelectedBaggageDataTip" + keyGenerated);

        if (_insuranceSelected && !init) {
            bkgData.insurancesSelected = _insuranceSelected;
            bkgData.insuranceOnce = _insuranceOnce;
        } else {
            bkgData.insurancesSelected = {};
            bkgData.insuranceOnce = 0;
        }
        $scope.b2b = 0;
        if (user.isLogged && user.type == 2)
            $scope.b2b = 1;


        $scope.paxType = paxType;
        $scope.paxAmount = paxAmount;
        $scope.cards = [];
        if (bkgData.airlines[bkgData.selection.outbound.marketingCarrier].fidelityCards != null)
            $scope.cards = bkgData.airlines[bkgData.selection.outbound.marketingCarrier].fidelityCards;
        // End Pax init

        // Init Fare
        if (bkgData.request.oneWay) {
            $scope.proposal = bkgData.selection.outbound.proposal;
            $scope.lastDate = bkgData.selection.outbound.arrivalDate;
        }
        else {
            $scope.proposal = bkgData.selection.inbound.proposal;
            $scope.lastDate = bkgData.selection.inbound.arrivalDate;
        }
        bkgData.amexEnabled = $scope.proposal.bankCards.getByKeyValue("key", "001") != null;
        $scope.proposal.additionalBaggages.each(function (ab) { ab.idx = ab.key == $scope.bkgData.selection.outbound.key ? 0 : 1; });
        // End Init Fare

        if ($scope.proposal.totalFlexy.value == 0) {
            //$scope.notReadyStep = 2;
            bkgData.bookingRequest.flexyFare = false;
        }

        $scope.updateBookingSummary = function () {
            $scope.$broadcast("onBookingSummaryUpdate");
        };

        //Branded Fares
         $scope.updateProductDetails= function () {
            $scope.$broadcast("onProductDetailsUpdate");
        };

        var changePaxesAndPayer = true;
        var paxHistory = tseUtil.Session.get('paxHistory');
        if (paxHistory != undefined && paxHistory.request != undefined && bkgData.request != undefined && $scope.b2b == 0 && init) {
            /*if (paxHistory.request.destination.value != bkgData.request.destination.value)
                changePaxesAndPayer = false;
            if (paxHistory.request.origin.value != bkgData.request.origin.value)
                changePaxesAndPayer = false;
            if (paxHistory.request.destination2 != undefined && bkgData.request.destination2 != undefined && paxHistory.request.destination2.value != bkgData.request.destination2.value)
                changePaxesAndPayer = false;
            if (paxHistory.request.origin2 != undefined && bkgData.request.origin2 != undefined && paxHistory.request.origin2.value != bkgData.request.origin2.value)
                changePaxesAndPayer = false;*/
            if (paxHistory.request.adults != bkgData.request.adults)
                changePaxesAndPayer = false;
            if (paxHistory.request.childs != bkgData.request.childs)
                changePaxesAndPayer = false;
            if (paxHistory.request.infants != bkgData.request.infants)
                changePaxesAndPayer = false;
        }
        else {
            changePaxesAndPayer = false;
        }

        if (changePaxesAndPayer) {
            bkgData.bookingRequest.paxes = paxHistory.paxes;
            if (paxHistory.payer != undefined)
                bkgData.bookingRequest.payer = paxHistory.payer;
            console.log("changed!");
        }

        $scope.$on("setReadyStep", function (event, args) {
            $scope.newProcess = false;

            if (args.step == 1 && $scope.hideInsurance == true) {
                $scope.$emit("getPaymentMethods", 2);
            } else {
                $scope.updateScreen(args.step + 1);
            }            
            $window.scrollTo(0, $($(".c-main > div")[1]).offset().top - 15);
        });

        $scope.$on("onFlexyClick", function (event, args) {
            //$scope.$apply(function () {
            bkgData.bookingRequest.flexyFare = args.isFlexy;
            bkgData.bookingRequest.insurance = null;
            bkgData.insuranceOnce = 0;
            bkgData.insurancesSelected = {};
            insuranceOnce.value = bkgData.insuranceOnce;
            //});
            $scope.updateBookingSummary();
        });

        //Best Price Click
        $scope.$on("onBestPriceClick", function (event, args) {
            bkgData.bookingRequest.bestPriceFare = args.isBestPrice;
            $scope.updateBookingSummary();
        });

        //START HERE: Branded Fares
        $scope.$on("brandClick", function (event, args) {
            $scope.updateBookingSummary();
            $scope.updateProductDetails();
        });
        //END HERE: Branded Fares

        $scope.$on("onInsuranceClick", function (event, args) {
            bkgData.insuranceOnce = 1;
            bkgData.insurancesSelected.isAnnul = 0;
            bkgData.insurancesSelected.isRepatriament = 0;
            bkgData.insurancesSelected.isAnnulRepatriament = 0;
            if (args.amount > 0) {
                if (args.type == "00001") { bkgData.insurancesSelected.isAnnul = args.amount; }
                if (args.type == "00003") { bkgData.insurancesSelected.isRepatriament = args.amount; }
                if (args.type == "00004") { bkgData.insurancesSelected.isRepatriament = args.amount; }
                if (args.type == "00005") { bkgData.insurancesSelected.isRepatriament = args.amount; }
                if (args.type == "00006") { bkgData.insurancesSelected.isRepatriament = args.amount; }
                if (args.type == "00002") { bkgData.insurancesSelected.isAnnulRepatriament = args.amount; }
                bkgData.bookingRequest.insurance = { company: args.company, insurance: args.type, id: args.id };
                $scope.$broadcast("disableInsurance", { type: args.type });
            }
            else if ((bkgData.bookingRequest.insurance != null) && (args.type == bkgData.bookingRequest.insurance.insurance)) {
                bkgData.bookingRequest.insurance = null;
            }
            insurancesSelected.value = bkgData.insurancesSelected;
            insuranceOnce.value = bkgData.insuranceOnce;

            $scope.updateBookingSummary();
        });
        $scope.$on("updateTotal", function (event, args) {
            $scope.updateBookingSummary();
        });

        $scope.$on("onBookingSummaryUpdateCompleted", function (event, args) {
            $scope.prixTotalWithoutMargin = bkgData.prixTotalWithoutMargin;

        });
        $scope.$on("getBanqueCasinoSchedule", function (event, value) {
            $window.FLoading({ vAction: 'show' });
            $scope.apiClient.getBankCasinoSchedule(bkgData.bookingRequest).success(function (data) {
                $window.FLoading({ vAction: 'hide' });
                if (data && data.status == 0 && data.banqueCasino) {
                    bkgData.banqueCasino = 2; //is good
                    bkgData.banqueCasinoSchedule = data.banqueCasino;
                    if (bkgData.banqueCasinoMessage == undefined) {
                        bkgData.banqueCasinoMessage = false;
                    }
                    bkgData.showBanqueCasino = 1;
                }
                else {
                    bkgData.banqueCasino = 3; //error
                    bkgData.banqueCasinoSchedule = null;
                    bkgData.showBanqueCasino = 0; //normal

                    //Comprobar si la cuenta no esta autorizada para Bank Casino
                    if (data && data.messages && data.messages[0] && data.messages[0].type == '10000')
                        bkgData.banqueCasinoCustomerUnauthorized = true;
                }
                if (value >= 0)
                    $scope.$emit("setReadyStep", { step: value });
                $scope.$broadcast("getBanqueCasinoScheduleCallback");
            }).error(function () {
                bkgData.banqueCasino = 3; //error
                bkgData.banqueCasinoSchedule = null;
                $window.FLoading({ vAction: 'hide' });
                if (value >= 0)
                    $scope.$emit("setReadyStep", { step: value });
                bkgData.showBanqueCasino = 0; //normal
                $scope.$broadcast("getBanqueCasinoScheduleCallback");
            });
        });

        //**********************************Branded Fares************************************************************************
        var getBrandedFares = function () {
            if (!$scope.bkgData.brandedFareRequested) {
                if (!isDefined($scope.proposal.allowUpsell))
                    $scope.proposal.allowUpsell = true;
                if ($scope.proposal.allowUpsell) {
                    $window.FLoading({ vAction: 'showNow' });
                    bkgData.brandedFares = null;
                    $scope.apiClient.getBrandedFares(bkgData.bookingRequest).success(function (data) {
                        $window.FLoading({ vAction: 'hide' });

                        //brandedFareRequested: indica si ya hicimos la solicitud getBrandedFares() la primera vez
                        $scope.bkgData.brandedFareRequested = true;
                        if (data) {
                            if (data.brands) {
                                data.brands.each(function (item) {
                                    if (item.fares) {
                                        item.fares.each(function (fare) {
                                            switch (fare.ptc) {
                                                case "CNN":
                                                    fare.paxType = 1;
                                                    break;
                                                case "INF":
                                                    fare.paxType = 2;
                                                    break;
                                                default:
                                                    fare.paxType = 0;
                                            }

                                            fare.totalFare = fare.quantity * fare.fare;

                                            //Prepare taxes details Object
                                            if (fare.airportTaxes > 0 || fare.surchargeTaxes > 0) {
                                                fare.airportTax = fare.airportTaxes;
                                                fare.surchargeTax = fare.surchargeTaxes;
                                                fare.amount = fare.fare;
                                            }
                                        });
                                    }

                                    //Flexies
                                    if (item.flexies) {
                                        item.flexies.each(function(f) {
                                            if (f.flexy) {
                                                var flexyAmount = f.flexy.flexyAmount;
                                                f.flexy = airHelper.getNumberObject(f.flexy.flexyAmount);
                                                f.flexyAmount = flexyAmount;
                                                if (f.code == "ADT") {
                                                    f.totalFlexy = f.flexyAmount * bkgData.request.adults;
                                                    f.paxType = 0;
                                                }
                                                if (f.code == "CNN") {
                                                    f.totalFlexy = f.flexyAmount * bkgData.request.childs;
                                                    f.paxType = 1;
                                                }
                                                if (f.code == "INF") {
                                                    f.totalFlexy = f.flexyAmount * bkgData.request.infants;
                                                    f.paxType = 2;
                                                }

                                                if (f.totalFlexy)
                                                    f.totalFlexy = airHelper.getNumberObject(f.totalFlexy);
                                            }
                                        });
                                    }

                                    item.flexies = item.flexies.groupByKey("paxType");

                                    //Best Prices
                                    if (item.bestPrices) {
                                        item.bestPrices.each(function(bestPriceItem) {
                                            if (bestPriceItem.bestPrice) {
                                                var bestPriceAmount = bestPriceItem.bestPrice.saleAmount;
                                                bestPriceItem.bestPrice = bestPriceAmount;
                                                if (bestPriceItem.code == "ADT") {
                                                    bestPriceItem.totalBestPrice =
                                                        bestPriceItem.bestPrice * bkgData.request.adults;
                                                    bestPriceItem.paxType = 0;
                                                }
                                                if (bestPriceItem.code == "CNN") {
                                                    bestPriceItem.totalBestPrice =
                                                        bestPriceItem.bestPrice * bkgData.request.childs;
                                                    bestPriceItem.paxType = 1;
                                                }
                                                if (bestPriceItem.code == "INF") {
                                                    bestPriceItem.totalBestPrice =
                                                        bestPriceItem.bestPrice * bkgData.request.infants;
                                                    bestPriceItem.paxType = 2;
                                                }
                                            }
                                        });
                                    }

                                    item.bestPrices = item.bestPrices.groupByKey("paxType");

                                    //Baggages

                                    var baggaeServiceIndex = null;
                                    data.services.each(function(s) {
                                        if (s.rficGroup == "BG" && s.rficSubGroup == null) {
                                            baggaeServiceIndex = s.index;

                                            if (item.includeBaggage == false || item.includeBaggage == undefined) {
                                                item.includeBaggage = item.services.any(function(bService) {
                                                    if (bService.index == baggaeServiceIndex &&
                                                        bService.servicePricing == 0)
                                                        return true;
                                                });
                                            }
                                        }
                                    });


                                    if (item.includeBaggage) {
                                        //Prepare text to display properly
                                        if ($window.$scope.bkgData.request.adults > 0) {
                                            item.baggageText = "1 baggage en soute (par ad.)";
                                            item.baggageDataTip = "1 baggage en soute (par ad.)\n";
                                        }
                                        if ($window.$scope.bkgData.request.childs > 0) {
                                            item.baggageText += " | 1 baggage en soute (par enf.)";
                                            item.baggageDataTip += "1 baggage en soute (par enf.)\n";
                                        }
                                        if ($window.$scope.bkgData.request.infants > 0) {
                                            item.baggageText += " | 1 baggage en soute (par bébé.)";
                                            item.baggageDataTip += "1 baggage en soute (par bébé.)";
                                        }
                                    }

                                    item.totalAmountFlexy = airHelper.getNumberObject(item.totalAmountFlexy);
                                    item.totalBestPrice = airHelper.getNumberObject(item.totalAmountBestPrice);

                                    if (item.displayName)
                                        item.displayName = item.displayName.capitalize();

                                    item.displayAdditionalAmount = airHelper.getNumberObject(item.additionalAmount);
                                });
                            }

                            if (data.services) {
                                data.services.each(function (item) {
                                    if (item.description) {
                                        item.descriptionHtml = item.description.replaceAll("\n", "<br/>");
                                        item.descriptionTip = item.description.replaceAll('\n', ' | ');
                                    }
                                    item.cssClass = (item.rficGroup + (item.rficSubGroup ? "-" + item.rficSubGroup : "")).toLowerCase();
                                });
                            }
                            bkgData.brandedFares = data;

                            if (data.brands[0]) {
                                data.brands[0].displayBestPriceOption = true;
                            }

                            if (data.brands[1]) {
                                data.brands[1].displayBestPriceOption = false;
                            }

                            if (data.brands[2]) {
                                data.brands[2].displayBestPriceOption = false;
                            }

                            //Seleccionamos el 0 por defecto
                            if (data.brands[0]) {
                                bkgData.bookingRequest.brandKey = bkgData.brandedFares.brands[0].brandKey;
                                bkgData.bookingRequest.brandFareSelected = bkgData.brandedFares.brands[0];
                            }
                        }
                        $scope.$broadcast("getBrandedFaresCallback");
                    }).error(function () {
                        $window.FLoading({ vAction: 'hide' });
                        bkgData.brandedFares = null;
                        $scope.$broadcast("getBrandedFaresCallback");
                    });
                }
                else {
                    $timeout(function () {
                        bkgData.brandedFares = null;
                        $scope.$broadcast("getBrandedFaresCallback");
                    }, 100);
                }
            }
        };

        if ($scope.enableBrandedFares)
            getBrandedFares();

        //**********************************End Branded Fares********************************************************************

        //**********************************Paypal********************************************************************************
        $scope.$on("getPayPalToken", function (event, value) {
            if ($scope.enablePaypal) {
                var paypalRQ = {
                    itineraries: [bkgData.selection.outbound.departure, bkgData.selection.outbound.arrival],
                    departureDate: bkgData.selection.outbound.departureDate,
                };
                if (bkgData.selection.inbound) {
                    paypalRQ.itineraries.push(bkgData.selection.inbound.departure);
                    paypalRQ.itineraries.push(bkgData.selection.inbound.arrival);
                }
                $scope.apiClient.getPayPalToken(paypalRQ).success(function (data) {
                    if (data)
                        bkgData.paypalToken = data.token;
                }).error(function (data) {
                });
            }
        });
        //**********************************End Paypal********************************************************************************

        //**********************************Payment Methods********************************************************************************
        $scope.$on("getPaymentMethods", function (event, value) {
            $window.FLoading({ vAction: 'show' });
            $scope.apiClient.getPaymentMethods(bkgData.bookingRequest).success(function (data) {
                $window.FLoading({ vAction: 'hide' });
                if (data) {
                    bkgData.paymentMethods = data.paymentMethods;
                }
                if (value >= 0)
                    $scope.$emit("setReadyStep", { step: value });
                $scope.$broadcast("getBanqueCasinoScheduleCallback");
            }).error(function (data) {
                if (value >= 0)
                    $scope.$emit("setReadyStep", { step: value });
                $scope.$broadcast("getBanqueCasinoScheduleCallback");
            });
        });
        //**********************************End Payment Methods********************************************************************************

        $scope.updateScreen = function (step) {
            if ($location.hash() == "" && step == 1)
            {
                //do nothing
            }
            else
                $location.hash(step);
            $scope.notReadyStep = step;
            bkgData.currentStep = step;
            bookingRequest.value = bkgData.bookingRequest;
            insurancesSelected.value = bkgData.insurancesSelected;
            insuranceOnce.value = bkgData.insuranceOnce;
            bookingRequestKey.value = keyGenerated;
            //$window.sendAnalytics('/Flightsearch/Purchase' + step, 'Purchase' + step);
            //Tracking
            try {
                //GTM
                if (trackingService.isActivated()) {
                    trackingService.pushPageData('/Flightsearch/Purchase' + step, 'Purchase' + step, lng, false);
                }
            }
            catch (ex) {
                console.log(ex.message);
                $window.trackingError = ex.message;
            }
            $window.sendInspectlet('virtualPage');
            $window.sendInspectlet("pageUrl", $window.getUrl('Flightsearch/Purchase' + step));
            $scope.updateBookingSummary();
        };
        $scope.updateBookingSummary();
        bookingRequest.value = bkgData.bookingRequest;
        insurancesSelected.value = bkgData.insurancesSelected;
        insuranceOnce.value = bkgData.insuranceOnce;
        bookingRequestKey.value = keyGenerated;

        //************************************************************************************************************************
        //Create Booking
        $scope.$on("createBooking", function (event, args) {
            $window.FLoading({ vAction: 'show' });
            //$window.sendAnalytics('/Flightsearch/Bookingwait', 'Booking wait');
            //Tracking
            try {
                //GTM
                if (trackingService.isActivated()) {
                    trackingService.pushPageData('/Flightsearch/Bookingwait', 'Booking wait', lng, false);
                }
            }
            catch (ex) {
                console.log(ex.message);
                $window.trackingError = ex.message;
            }

            //$window.sendXiti("tunnel_resavol::attente_confirmation_site_resavol", 1);
            $window.sendInspectlet("pageUrl", $window.getUrl('Flightsearch/Bookingwait'));
            window.sendDoubleClick("recapvol", null, null, null, null);

            $scope.apiClient.processBooking(args.request).success(function (data) {
                bkgData.bookingResponse = data;
                tseUtil.Session.set('bkgData_' + $window.site.currentAirRequest.requestId, bkgData);
                paymentUrl = tseUtil.Url.appendValueToUrlBefore($window.site.currentAirRequest.paymentUrl, "brqid", data.bookingRequestId);
                $window.location.href = paymentUrl;
            }).error(function (data, code) {
                $window.FLoading({ vAction: 'hide' });
                if (code == 400) {
                    $window.XLConfirm({
                        vTitle: $i18next.t("airbooking.yoursessionlost"), vTextYes: "OK", vHideNo: true, fCallBackYes: function () {
                            $window.location.href = $window.site.loginUrl;
                        }
                    });
                }
                if (code == 410) {
                    $window.XLConfirm({
                        vTitle: $i18next.t("airbooking.yoursessionlost"), vTextYes: "OK", vHideNo: true, fCallBackYes: function () {
                            $window.redoSearch();
                        }
                    });
                }
                if (code == 412) {
                    $window.XLConfirm({ vTitle: $i18next.t("airbooking.pleasetheform"), vTextYes: "OK", vHideNo: true });
                }
            });
        });

        //************************************************************************************************************************
        //Additional Bookings
        $scope.$on("addBaggagesChanged", function (event, args) {
            $scope.updateBookingSummary();
        });
        //************************************************************************************************************************
        //OnLoad
        if (window.location.hash != "") {
            var step = parseInt(window.location.hash.substring(1));
            if (step > 0) $scope.updateScreen(step);
            else $scope.updateScreen(1);
        }
        else
            $scope.updateScreen(1);

        //Detect if hash changes.
        var hashChanged = function (callback) {
            var lastHash = $location.hash();
            var innerFunc = function () {
                if ($location.hash() != lastHash) {
                    lastHash = $location.hash();
                    if (callback)
                        callback(lastHash);
                }
                setTimeout(innerFunc, 100);
            };
            setTimeout(innerFunc, 100);
        };
        hashChanged(function (hashValue) {
            if (hashValue == "") hashValue = "#1";
            var step = parseInt(hashValue.substring(1));

            if (step > 0) {
                $scope.$apply(function () {
                    if ($scope.notReadyStep != step) {
                        $scope.updateScreen(step);
                        setTimeout(function () {
                            $window.scrollTo(0, 0);
                        }, 100);
                    }
                });
            }
        });
        //************************************************************************************************************************

        //Taxes details click
        $scope.$on("displayTaxesDetailsClick", function (event, args) {
            $scope.displayTaxesDetailModal = true;
            $scope.modalTaxesKey = args.key.replaceAll(":", "-");
            $scope.modalTaxesKey = $scope.modalTaxesKey.replaceAll("\\|", "-");
            $scope.modalTaxesKeyAux = $scope.modalTaxesKey + "-" + args.paxType;
            $scope.modalTaxesPaxType = args.paxType;
            $scope.modalTaxesPaxFares = args.paxFares;
            $scope.modalPaxNumber = args.paxNumber;
            $scope.$broadcast("updateTaxesDetailModalData", { key: $scope.modalTaxesKey, paxType: $scope.modalTaxesPaxType, fares: $scope.modalTaxesPaxFares, paxnumber: $scope.modalPaxNumber });

            //Remodal workaround
            $(document).find(".remodal").each(function (a, c) {
                var d = $(c), e = d.data("remodal-options");
                e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                    d["remodal"](e)
            });

            $timeout(function () {
                $("[data-remodal-id='taxes-detail-modal-" + $scope.modalTaxesKeyAux + "']").remodal().open();
                },
                200, false);

        });

        $scope.$on("selectAirbusInsurance", function (event, args) {
            bkgData.bookingRequest.isAirbusInsuranceSelected = true;
            bkgData.bookingRequest.isAirbusInsuranceUncheked = false;
        });

        $scope.$on("rejectAirbusInsurance", function (event, args) {
            bkgData.bookingRequest.isAirbusInsuranceSelected = false;
            bkgData.bookingRequest.isAirbusInsuranceUncheked = true;
        });

        $scope.$on("selectAirbusPaymentBalanceOption", function (event, args) {
            bkgData.bookingRequest.airbusPayOption = args.airbusPayOption;
        });
    }
    else {
        $window.FLoading({ vAction: 'show' });
        window.location.href = searchUrl;
    }
}]);
